import { AuthContext } from "embeddable-dashboards/lib/AuthContext";
import { Outlet } from "react-router-dom";
import React, { useEffect } from "react";
/*
keep this in sync with the following :( :
- api/src/handlers/embeddableDashboard/index.ts
- api/openapi/versions/v1.yml
- embeddable-dashboards/src/pages/customer/CustomerDashboard.tsx
- dashboard-playground/src/constants.ts
*/
const colorToCssVarMapping = [
  { name: "grey800", cssVarName: "--color-grey-800" },
  { name: "grey800", cssVarName: "--color-default-font" },
  { name: "grey600", cssVarName: "--color-grey-600" },
  { name: "grey100", cssVarName: "--color-grey-100" },
  { name: "grey50", cssVarName: "--color-grey-50" },
  { name: "grey00", cssVarName: "--color-grey-00" },
  { name: "grey00", cssVarName: "--color-background" },
  { name: "primary600", cssVarName: "--color-primary-600" },
  { name: "primary50", cssVarName: "--color-primary-50" },
  { name: "success600", cssVarName: "--color-success-600" },
  { name: "error600", cssVarName: "--color-error-600" },
];

const validColorNames = colorToCssVarMapping.map(({ name }) => name);
function DashboardPage() {
  // handle color changes
  useEffect(() => {
    if (window.location.search.includes("colorOverrides=")) {
      const urlParams = new URLSearchParams(window.location.search);
      try {
        const colorOverrides = JSON.parse(
          urlParams.get("colorOverrides") || "[]",
        ) as Array<{ name: string; value: string }>;
        const colorOverridesCss = `:root {
          ${colorOverrides
            .filter(
              ({ name, value }) =>
                validColorNames.includes(name) &&
                value.match(/^#[0-9a-f]{3,6}$/i),
            )
            .flatMap(({ name, value }) =>
              colorToCssVarMapping
                .filter((m) => m.name === name)
                .map(({ cssVarName }) => `${cssVarName}: ${value}; `),
            )
            .join("\n")}
        }`;

        const head = document.head || document.getElementsByTagName("head")[0];
        const style = document.createElement("style");
        head.appendChild(style);
        style.appendChild(document.createTextNode(colorOverridesCss));
      } catch {
        // do nothing
      }
    }
  }, []);

  const { authToken } = AuthContext.useContainer();
  return <>{authToken && <Outlet />}</>;
}

export default DashboardPage;
