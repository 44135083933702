/* ce2b378499e427f1be70e14cc7db37aeecf9307f
 * This file is automatically generated by graphql-let. */

import * as Types from '../../../../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RetrieveCustomerPlanUsageQueryVariables = Types.Exact<{
  start_date: Types.Scalars['timestamptz'];
  end_date: Types.Scalars['timestamptz'];
}>;


export type RetrieveCustomerPlanUsageQuery = { __typename?: 'Query', Customer: Array<{ __typename?: 'Customer', id: string, current_plan_usage: Array<{ __typename?: 'CurrentPlanUsage', group_key: string | null, billable_metric: { __typename?: 'BillableMetric', id: string, name: string }, usage: Array<{ __typename?: 'UsageWindow', end: string, start: string, metric_value: string | null, groups: Array<{ __typename?: 'UsageGroup', group_value: string, metric_value: string | null }> | null }> }> | null }> };


export const RetrieveCustomerPlanUsageDocument = gql`
    query RetrieveCustomerPlanUsage($start_date: timestamptz!, $end_date: timestamptz!) {
  Customer {
    id
    current_plan_usage(start_date: $start_date, end_date: $end_date) {
      billable_metric {
        id
        name
        __environment_type: environment_type
      }
      group_key
      usage {
        end
        start
        metric_value
        groups {
          group_value
          metric_value
        }
      }
    }
    __environment_type: environment_type
  }
}
    `;

/**
 * __useRetrieveCustomerPlanUsageQuery__
 *
 * To run a query within a React component, call `useRetrieveCustomerPlanUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveCustomerPlanUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveCustomerPlanUsageQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *   },
 * });
 */
export function useRetrieveCustomerPlanUsageQuery(baseOptions: Apollo.QueryHookOptions<RetrieveCustomerPlanUsageQuery, RetrieveCustomerPlanUsageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetrieveCustomerPlanUsageQuery, RetrieveCustomerPlanUsageQueryVariables>(RetrieveCustomerPlanUsageDocument, options);
      }
export function useRetrieveCustomerPlanUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetrieveCustomerPlanUsageQuery, RetrieveCustomerPlanUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetrieveCustomerPlanUsageQuery, RetrieveCustomerPlanUsageQueryVariables>(RetrieveCustomerPlanUsageDocument, options);
        }
export type RetrieveCustomerPlanUsageQueryHookResult = ReturnType<typeof useRetrieveCustomerPlanUsageQuery>;
export type RetrieveCustomerPlanUsageLazyQueryHookResult = ReturnType<typeof useRetrieveCustomerPlanUsageLazyQuery>;
export type RetrieveCustomerPlanUsageQueryResult = Apollo.QueryResult<RetrieveCustomerPlanUsageQuery, RetrieveCustomerPlanUsageQueryVariables>;